@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,500&display=swap');
/* @import url('https://fonts.cdnfonts.com/css/reem-kufi'); */

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Roboto Flex', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ead6a7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #ff804e; */
  background: #997d3d;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #f3cc71;
}
